import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/articles-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Hola`}</strong>{` 👋, este es un blog de desarrollo, así que abajo dejo un `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Hola Mundo`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`Hola Mundo`}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      